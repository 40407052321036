.button {
    background-color: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
    padding: 12px 15px;
    outline: 0;
    text-transform: uppercase;
    box-shadow: none;
    border: none;
    font-size: .8em;
    font-weight: normal;
    border-radius: 3px;
}

.button:active {
    filter: brightness(80%);
}

.button:disabled {
    cursor: default;
    opacity: 0.7;
}
